@use "sass:math";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";

$sizeRadarFrame: 134;
$sizeCityPoint: 4;

.mapContainer {
  position: absolute;
  pointer-events: none;
  bottom: -330px;
  right: 0;
  fill: #fff;

  @include mqMaxWidth($screenM) {
    right: -575px;
  }

  @include mqMaxWidth($screenS) {
    right: -835px;
  }

  @include mqMaxWidth($screenXs) {
    right: -875px;
  }
}

.mapImage {
  display: block;
  width: 1196px;
  height: 583px;
  position: relative;
  right: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("assets/worldMap.webp");
}

.wrapper {
  position: relative;
  height: 100%;
}

.animationInitialState {
  width: $sizeRadarFrame + px;
  height: $sizeRadarFrame + px;
  // size inversion is necessary to optimize the scale() for safari browsers
  transform: scale(math.div($sizeCityPoint, $sizeRadarFrame));
}

.cityIndicator {
  @extend .animationInitialState;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
}

@keyframes radar {
  0% {
    transform: scale(math.div($sizeCityPoint, $sizeRadarFrame));
  }
  10% {
    transform: scale(1);
  }
  100% {
    transform: scale(math.div($sizeCityPoint, $sizeRadarFrame));
  }
}

@keyframes smallRadar {
  0% {
    transform: scale(math.div($sizeCityPoint, $sizeRadarFrame));
  }
  10% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(math.div($sizeCityPoint, $sizeRadarFrame));
  }
}

.radarBigCircle {
  @extend .cityIndicator;
  opacity: 0.05;

  animation-name: radar;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.radarSmallCircle {
  @extend .radarBigCircle;

  opacity: 0.1;
  animation-name: smallRadar;
}

.cityContainer {
  position: absolute;
  width: $sizeRadarFrame + px;
  height: $sizeRadarFrame + px;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.newYork {
  @extend .cityContainer;
  top: 30px;
  left: 256px;
}

.kyiv {
  @extend .cityContainer;
  top: -5px;
  left: 603px;

  .radarBigCircle {
    animation-delay: 1.3s;
  }
}

.london {
  @extend .cityContainer;
  top: -16px;
  left: 502px;

  .radarBigCircle {
    animation-delay: 1.7s;
  }
}

.hongKong {
  @extend .cityContainer;
  top: 100px;
  left: 891px;

  .radarBigCircle {
    animation-delay: 2.8s;
  }
}

.miami {
  @extend .cityContainer;
  top: 90px;
  left: 230px;

  .radarBigCircle {
    animation-delay: 3.5s;
  }
}

.singapore {
  @extend .cityContainer;
  top: 171px;
  left: 850px;

  .radarBigCircle {
    animation-delay: 4.2s;
  }
}
